(function(LIVEREZ, window, $, undefined) {
  var CALENDAR_DAY_TYPES = {
    Available: 0,
    Unavailable: 1,
    CheckIn: 2,
    CheckOut: 3,
    EarlyCheckIn: 4,
    LateCheckOut: 5,
    EarlyCheckInLateCheckOut: 6,
    SingleNightRental: 7,
    TurnOver: 8,
    TurnOverEarlyCheckIn: 9,
    TurnOverLateCheckOut: 10,
  };

  var CalendarAvailability = function(dynsiteId, adminCustDataID, pageDataId) {
    this.dynsiteId = dynsiteId;
    this.adminCustDataId = adminCustDataID;
    this.pageDataId = pageDataId;

    this.unavailableTypes = [
      CALENDAR_DAY_TYPES.Unavailable,
      CALENDAR_DAY_TYPES.EarlyCheckIn,
      CALENDAR_DAY_TYPES.LateCheckOut,
      CALENDAR_DAY_TYPES.EarlyCheckInLateCheckOut,
      CALENDAR_DAY_TYPES.SingleNightRental,
      CALENDAR_DAY_TYPES.TurnOver,
      CALENDAR_DAY_TYPES.TurnOverEarlyCheckIn,
      CALENDAR_DAY_TYPES.TurnOverLateCheckOut,
    ];

    this.unavailableDateObjs = [];
    this.arrivalDateObjs = [];
    this.departureDateObjs = [];
    this.unavailableDateObjsMobile = [];
    this.queriedYears = [];
  };

  CalendarAvailability.prototype.getCalendarData = function(startDate, endDate, callback) {
    var _this = this
    
    if (_this.xhr && _this.xhr.readystate != 4) {
      _this.xhr.abort();
      _this.xhr = null;
    }

    this.queriedYears.push(new Date(startDate).getFullYear());

    _this.xhr = $.ajax({
      type: 'get',
      url:
        LIVEREZ.DynsiteFunctions.getServerName() +
        '/inc/api/webservices.aspx?method=propertycalendardata',
      data: {
        AdminCustDataID: _this.adminCustDataId,
        DynSiteID: _this.dynsiteId,
        PageDataID: _this.pageDataId,
        ad: startDate,
        dd: endDate,
      },
      dataType: 'json',
      success: function(data) {
        try {
          data.forEach(function (d) {
            var formattedDate = LIVEREZ.DynsiteFunctions.dateStringToObject(d.startDateString)
            if (dateFormatType === 1) {
                d.startDateString = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(d.startDateString, dateFormatType);
            }
    
            switch(d.calendarDayTypes) {
              case CALENDAR_DAY_TYPES.CheckIn:
                _this.arrivalDateObjs.push(d.startDateString);
                break;
              case CALENDAR_DAY_TYPES.CheckOut:
                _this.departureDateObjs.push(d.startDateString);
                break;
              default:
                _this.unavailableDateObjs.push(d.startDateString);
                _this.unavailableDateObjsMobile.push(formattedDate);
            }
          });
            
          _this.unavailableDateObjs = _this.unavailableDateObjs.filter(function(
            element,
            index,
            array
          ) {
            return array.indexOf(element) >= index;
          });

          _this.unavailableDateObjsMobile = _this.unavailableDateObjsMobile.filter(function(
            element,
            index,
            array
          ) {
            return array.indexOf(element) >= index;
          });

          callback();
        } catch (ex) {
          console.error(ex);
          callback();
        }
      },
      error: function(data, status, error) {
        $('.datepicker-overlay').remove();

        console.log(data);
        console.log(status);
        console.log(error);
      },
    });
  };

  // function isDST(t) { //t is the date object to check, returns true if daylight saving time is in effect.
  // 	var jan = new Date(t.getFullYear(),0,1);
  // 	var jul = new Date(t.getFullYear(),6,1);
  // 	return Math.min(jan.getTimezoneOffset(),jul.getTimezoneOffset()) == t.getTimezoneOffset();
  // }

  CalendarAvailability.prototype.findDateConflicts = function(start, end) {
    var error = false;

    var start = new Date(LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(start, dateFormatType));
    var end = new Date(LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(end, dateFormatType));

    for (var i = 0; i < this.unavailableDateObjs.length; i++) {
      var tmpDate = this.unavailableDateObjs[i];
      if (tmpDate >= start && tmpDate <= end) {
        error = true;
      }
    }

    return error;
  };

  LIVEREZ.CalendarAvailability = CalendarAvailability;
})((window.LIVEREZ = window.LIVEREZ || {}), window, lrjQ);